import { useState } from "react";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { createLanguage } from "../../DataService/LanguageDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { Modal, Button } from "react-bootstrap"; // Import required components from react-bootstrap


export const CreateLanguage = ({ show, handleClose }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [language, setLanguage] = useState({
    AlternativeLanguageID: null,
    Code: "",
    Name: "",
    Translations: null,
  });
  const { setCustomMessage } = useActiveInput();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLanguage((prevLanguage) => ({
      ...prevLanguage,
      [name]: value,
    }));
  };

  const handleCreateLanguage = () => {
    if (!language.Code || !language.Name) {
      notify("Please fill in all fields!", 'error');
      return;
    }
    createLanguage(
      ApiUrls.lanugageApi,
      sessionToken,
      language,
      notify,
      setCustomMessage,
      setLanguage).then(() => {
        // Reset tenant state and close modal after successful creation
        setLanguage({ Code:"" , Name: "" });
        handleClose(); // Close the modal on successful tenant creation
      });;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Language</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Language Code"
            name="Code"
            value={language.Code}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="Name"
            value={language.Name}
            onChange={handleInputChange}
          />
        </div>
</Modal.Body>
<Modal.Footer>
        <div className="d-flex gap-2 w-100"> 
        <Button
          className="btn bg-myColor w-100"
          onClick={handleCreateLanguage}
        >
          Add lanugage
        </Button>
        <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
      
      <ToastContainer />
    </>
  );
};
