import { useCallback, useEffect, useState } from "react";
import {
  fetchData,
  handleListNavigation,
  filterListByName,
  getProperties,
  notify,
} from "../../Utils/ReusableFunctions";
import { MDBTabs, MDBTabsItem, MDBTabsLink,MDBInput } from "mdb-react-ui-kit";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { ToastContainer } from "react-toastify";
import { updateCurrency } from "../../DataService/CurrencyDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { useSearch } from "../../Context/SearchContext";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import{faPlus} from '@fortawesome/free-solid-svg-icons';
import { CreateCurrency } from "./CreateCurrency";


export const Currency = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { languageOptions, setCustomMessage } = useActiveInput();
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const {searchString} = useSearch();  // Use the search string from context
  /*const [searchString, setSearchString] = useState("");*/
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

     //Show modal to create
     const [showModal, setShowModal] = useState(false);
     const handleClose = () => {
       setShowModal(false);
       // Fetch data again to refresh the list
       fetchData(ApiUrls.currencyApi, sessionToken).then((data) => {
         setCurrencyList(data.value);
       });
     };  
     const handleShow = () => setShowModal (true);

  const [activeTab, setActiveTab] = useState('general'); 

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    console.log('Tab clicked:', activeTab); // Debugging
  };

   // Set the first currency as selected on component mount
   useEffect(() => {
    if (currencyList.length > 0) {
      setSelectedCurrency(currencyList[0]); // Set the first role as selected
    }
  }, [currencyList]);

  const handleButtonClick = () => {
   // navigate("/menu/createcurrency");
   handleShow();
  };
  
  const filteredList = filterListByName(currencyList, searchString);

  const handleGetProperties = useCallback(() => {
    getProperties(ApiUrls.currencyPropertiesApi, sessionToken, languageOptions, setProperties);
  }, [sessionToken, languageOptions]);

  useEffect(() => {
    fetchData(ApiUrls.currencyApi, sessionToken).then((data) => {
      setCurrencyList(data.value);
      console.log(data);

      handleGetProperties();
    });
  }, [sessionToken, handleGetProperties]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedCurrency,
        setSelectedCurrency
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedCurrency]);

  const handleSave = () => {
    updateCurrency(
      ApiUrls.currencyApi,
      sessionToken,
      selectedCurrency,
      fetchData,
      setCurrencyList,
      notify,
      setCustomMessage
    );
  };

  const handleChange = (field, value) => {
    setSelectedCurrency((prevCurrency) => ({
      ...prevCurrency,
      [field]: value,
    }));
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage('');
    }
  };

  return (
    <div className="container-fluid d-flex flex-column min-vh-100 mt-0 p-0">
      <div className="row">
        {/* Heading and Add Currency Button */}
        <div className="row mb-0">
          <div className="col-lg-2 col-12 d-flex mb-0 mt-0">
            <h5 className="mb-0">Currencies</h5>
            <button
              className="no-border d-flex ms-auto"
              style={{ width: "auto" }}
              onClick={handleButtonClick}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="myColor"
                size="2x"
                style={{ marginRight: "0.5rem", fontSize: "1.5rem" }}
              />
            </button>
          </div>
  
          {/* Tabs (if a currency is selected) */}
          {selectedCurrency && (
            <div className="col-lg-10 col-md-12 col-sm-12 d-none d-md-flex mb-0 align-items-center">
              <MDBTabs fill className="mb-0">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleTabClick("general")}
                    active={activeTab === "general"}
                    className={`custom-tab-link ${activeTab === "general" ? "active" : ""}`}
                    style={{ textTransform: "none", fontSize: "1rem" }}
                  >
                    General
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
            </div>
          )}
        </div>
  
        <div className="row">
          {/* Currency Table */}         
        <div className="col-lg-2 col-md-12 col-sm-12 mt-0 p-0"> {/* Adjust width if necessary */}
          <div className="list-container no-spacing row g-0" style={{height:'48rem'}}>
            <div className="table-responsive">
              <table className="table table-hover table-fixed" style={{ textAlign: "left" }}>
                <tbody>
                  {filteredList.map((item) => (
                    <tr
                      key={item.ID}
                      className={item.ID === selectedCurrency?.ID ? "IdSelectedColor" : ""}
                      onClick={() => setSelectedCurrency(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>
                        <div className="info">
                          <h6 className="truncate-text">{item.Name}</h6>
                          <div className="truncate-text">Code: {item.Code}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>


                {/* Responsive Tabs Display for Small Screens */}
                <div className="row d-md-none">
            <div className="col-12">
              {selectedCurrency && (
                <MDBTabs fill className="mb-0">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('general')}
                      active={activeTab === 'general'}
                      className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >
                      General
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('roles')}
                      active={activeTab === 'roles'}
                      className={`custom-tab-link ${activeTab === 'roles' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >                     
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              )}
            </div>
          </div>
  
          {/* Currency Details */}
          <div className="col-lg-5 col-md-12 col-sm-12 mt-3">
            {selectedCurrency && (
              <div className="tab-content">
                {/* General Tab */}
                {activeTab === "general" && (
                  <div className="tab-pane active">
                    <MDBInput
                      name="Code"
                      id="Code"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedCurrency.Code}
                      onChange={(e) => handleChange("Code", e.target.value)}
                      label="Code"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Name"
                      id="Name"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedCurrency.Name}
                      onChange={(e) => handleChange("Name", e.target.value)}
                      label="Name"
                      type="text"
                      size="md"
                    />
                    <div className="d-flex gap-2">
                      <button onClick={handleSave} className="btn w-100 bg-myColor">
                        Save
                      </button>
                      
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <CreateCurrency show= {showModal} handleClose={handleClose}/>
    </div>
  );
}  