import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import AddRoleAssignments from './AddRoleAssignments';
import { notify } from '../../Utils/ReusableFunctions';
import { deleteUserAssignedRoles } from '../../DataService/RolesDataService';

const RoleTab = ({ selectedUser, selectedRoleIds, refreshUserList }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [activeTab, setActiveTab] = useState('Add role assignments'); 
  const [selectedRoleIdsState, setSelectedRoleIds] = useState([]); // Corrected state declaration
  const [rolesDeleted, setRolesDeleted] = useState(false); // Track if roles are deleted

  useEffect(() => {
    if (selectedRoleIds.length > 0) {
      handleTabClick('Remove role assignments'); // Activate the tab
    } else {
      handleTabClick('Add role assignments'); // Or set to a default tab if no roles are selected
    }
  }, [selectedRoleIds]); // Run this effect whenever selectedRoleIds changes

  // Handler for setting active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAddTabClick = () => {    
    console.log("AddRoleAssignment window opens");
    console.log("Selected User:", selectedUser);
  };

  const handleDeleteTabClick = async () => {
    if (selectedRoleIds.length === 0) {
      console.log("check the roles to delete");
      notify("Select the Roles to delete");
      setRolesDeleted(true); // Set rolesDeleted to true after successful deletion
      setSelectedRoleIds([]); // Clear selectedRoleIds state
      refreshUserList();
      return; // Exit early if no roles are selected
    }
    
    if (selectedRoleIds.length > 0) {
      try {       
        await deleteUserAssignedRoles(selectedRoleIds, sessionToken, notify);
        notify('Roles successfully deleted!', 'success');
        console.log("Roles deleted:", selectedRoleIds);
        refreshUserList(); // Call the refresh function to update the list in User.js
      } catch (error) {
        notify('Failed to delete roles', 'error');
      }
    }
  };

  return (
    <div className='tab-pane mt-3'>
      <table cellPadding="0" cellSpacing="0" style={{ width: '100%' }}>
        <thead style={{ borderBottom: '1px solid #e0e0e0' }}>
          <tr>
            <td className="text-start mb-3" style={{ padding: '0' }}>
              <div className="d-flex justify-content-start" style={{ gap: '0' }}>
                <button
                  className="no-border d-flex align-items-center"
                  style={{ width: 'auto', padding: '0', margin: '0' }}
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRoleAssignments" // ID should match the off-canvas ID
                  onClick={() => {                   
                    handleTabClick('Add role assignments'); // Set the active tab to 'Add role assignments'
                    handleAddTabClick(); // Call function for adding a role
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={activeTab === 'Add role assignments' ? 'myColor' : 'TabIconColor'}
                    style={{ marginRight: '0.5rem', fontWeight: 'normal', fontSize: '24px' }}
                    size="2x"
                  />
                  <p style={{
                    margin: '0',
                    lineHeight: '1.5rem',
                    fontSize: '14px',
                    color: activeTab === 'Add role assignments' ? 'black' : 'grey', // Change label color 
                  }}>
                    Add role assignments
                  </p>
                </button>
                <button
                  className="no-border d-flex align-items-center"
                  style={{ width: 'auto', padding: '0', margin: '0' }}
                  onClick={handleDeleteTabClick} // Call function for deleting a role
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={activeTab === 'Remove role assignments' ? 'myColor' : 'TabIconColor'}
                    style={{
                      marginLeft: '2rem',
                      marginRight: '0.5rem',
                      fontSize: '24px'
                    }}
                    size="2x"
                  />
                  <p style={{
                    margin: '0',
                    lineHeight: '1.5rem',
                    fontSize: '14px',
                    color: activeTab === 'Remove role assignments' ? 'black' : 'grey', // Change label color
                  }}>
                    Remove role assignments
                  </p>
                </button>
              </div>
            </td>
          </tr>
        </thead>
      </table>

      {/* Off-canvas for AddRoleAssignments */}
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasRoleAssignments"
        aria-labelledby="offcanvasRoleAssignmentsLabel"
        data-bs-backdrop="static" 
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRoleAssignmentsLabel">Assign Role to User</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"        
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* AddRoleAssignments component */}
          <AddRoleAssignments 
            selectedUser={selectedUser} 
            refreshUserList={refreshUserList}
            setSelectedRoleIds={setSelectedRoleIds} // Pass the setter as a prop if needed
          />        
        </div>
      </div>
    </div>
  );
};

export default RoleTab;
