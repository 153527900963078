import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Users } from "../User/Users";
import { CreateUser } from "../User/CreateUser";
import { Country } from "../Country/Country";
import { Languages } from "../Language/Languages";
import { CreateLanguage } from "../Language/CreateLanguage";
import { Roles } from "../Roles/Roles";
import { Tenant } from "../Tenant/Tenant";
import { CreateTenant } from "../Tenant/CreateTenant";
import { Application } from "../Application/Application";
import { CreateApplication } from "../Application/CreateApplication";
import { CreateRole } from "../Roles/CreateRole";
import { ApiKeys } from "../ApiKeys/ApiKeys.js";
import { CreateApikey } from "../ApiKeys/CreateApikey.js";
import { CreateCountry } from "../Country/CreateCountry.js";
import { Currency } from "../Currency/Currency.js";
import { CreateCurrency } from "../Currency/CreateCurrency.js";
import { UserProfile } from "../UserProfile/UserProfile.js";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./SideBar.css";
import { 
  faUser, 
  faGlobe, 
  faLanguage, 
  faUserShield, 
  faBuilding, 
  faCogs, 
  faKey, 
  faDollarSign,
  faAngleDoubleRight,
  faAngleDoubleLeft, 
  faBars,faSliders 
} from '@fortawesome/free-solid-svg-icons';


export const SideBar = ({isOpen, toggleSidebar,offcanvasSidebar}) => {
 
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation(); 

 //const offcanvasSidebar = () => setIsOpen(!isOpen);    //for smaller screens
  
  const isActive = (path) => location.pathname === path;
  const toggleSidebarIcons = () => setIsCollapsed(!isCollapsed);

 

  useEffect(() => {
    const updateSidebarState = () => {
      setIsCollapsed(window.innerWidth < 990);
    };
    updateSidebarState();
    window.addEventListener("resize", updateSidebarState);
    return () => window.removeEventListener("resize", updateSidebarState);
  }, []);

  const menuItems = [
    { path: "/menu/users", icon: faUser, label: "Users" },
    { path: "/menu/roles", icon: faUserShield, label: "Roles" },
    { path: "/menu/tenants", icon: faBuilding, label: "Tenants" },
    { path: "/menu/applications", icon: faCogs, label: "Applications" },
    { path: "/menu/apikeys", icon: faKey, label: "API Keys" },
    { path: "/menu/countries", icon: faGlobe, label: "Countries" },
    { path: "/menu/languages", icon: faLanguage, label: "Languages" },
    { path: "/menu/currency", icon: faDollarSign, label: "Currencies" }
  ];

  
  return (   
   
    <div className="container-fluid mt-0">
      <div className="row flex-nowrap">
        
        <div className={`col-auto col-md-2 d-none d-lg-flex flex-column ${isCollapsed ? "collapsed-sidebar" : ""}`}
             style={{
               width: isCollapsed ? "4rem" : "9rem",
               borderRight: "solid 0.1rem lightgrey",
               transition: "width 0.3s",               
               padding: "0",
               margin: "0rem"
             }}
        >
          <div className="d-flex flex-column min-vh-100 p-0">          
            <ul className="list-group d-none d-lg-flex list-group-flush mt-1 px-0">
            <button className="no-border d-flex align-items-center" onClick={toggleSidebarIcons}>
              <FontAwesomeIcon
                icon={isCollapsed ? faAngleDoubleRight : faAngleDoubleLeft}
                size="lg"
                className="myColor mt-2 px-2"
                style={{ marginRight: '0.5rem' }}
              />
            </button>
            {menuItems.map(({ path, icon, label }) => (
              <li
                key={path}
                className={`list-group-item list-group-item-action g-5 sidebar-item ${isActive(path) ? "bg-myColor-active" : ""}`}
                style={{ padding: 0 }}
              >
                <Link
                  to={path}
                  className={`text-dark sidebar-link d-flex align-items-center g-5 w-100 p-2 ${isCollapsed ? "justify-content-center" : ""}`}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="icon-column g-5" style={{ marginRight: isCollapsed ? '0' : '0.5rem', minWidth: '1.5rem', textAlign: 'center' }}>
                    <FontAwesomeIcon icon={icon} size="lg" color="gray" fixedWidth />
                  </div>

                  {!isCollapsed && (
                    <div className="label-column ms-2">
                      {label}
                    </div>
                  )}
                </Link>
              </li>
            ))}
        </ul>
    </div>
         
      </div>
            
      {/* Offcanvas sidebar for smaller screens */}
      <div className={`offcanvas offcanvas-start ${isOpen ? 'show' : ''}`} tabIndex="-1" id="offcanvasExample">
        <div className="offcanvas-header">
          <button type="button" className="btn-close" onClick={offcanvasSidebar}></button>
        </div>
        <div className="offcanvas-body">
          <ul className="list-group list-group-flush p-0">
            {menuItems.map(({ path, icon, label }) => (
            <li key={path} className={`list-group-item list-group-item-action sidebar-item ${isActive(path) ? "bg-myColor-active" : ""}`}>
                          <Link to={path} className="text-dark sidebar-link d-flex" onClick={offcanvasSidebar}>
                            <div className="icon-column">
                              <FontAwesomeIcon icon={icon} color="gray" fixedWidth />
                            </div>
                            <span className="ms-2">{label}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
            </div>
        
        <div className="col py-3">
          <Routes>
            <Route path="/" element={<Outlet />}>
              <Route path="/users" element={<Users />} />
              <Route path="/createuser" element={<CreateUser />} />
              <Route path="/countries" element={<Country />} />
              <Route path="/languages" element={<Languages />} />
              <Route path="/createlanguage" element={<CreateLanguage />} />
              <Route path="/roles" element={<Roles />} />
              <Route path="/tenants" element={<Tenant />} />
              <Route path="/createtenants" element={<CreateTenant />} />
              <Route path="/applications" element={<Application />} />
              <Route path="/createapplication" element={<CreateApplication />} />              
              <Route path="/createrole" element={<CreateRole />} />
              <Route path="/apikeys" element={<ApiKeys />} />
              <Route path="/createapikey" element={<CreateApikey />} />
              <Route path="/createcountry" element={<CreateCountry />} /> 
              <Route path="/currency" element={<Currency />} />
              <Route path="/createcurrency" element={<CreateCurrency />} />
              <Route path="/userprofile/:id" element={<UserProfile />} />
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
};