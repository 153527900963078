import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { notify } from "../../Utils/ReusableFunctions";
import { createRole } from "../../DataService/RolesDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { getFilteredRoles } from "../../DataService/RolesDataService";
import { useActiveInput } from "../../Context/ActiveInputTooltip";


export const CreateRole = ({ show, handleClose }) => {
    const sessionToken = localStorage.getItem("bearerToken");
    const [role, setRole] = useState({ Name: "" });
    const { setCustomMessage } = useActiveInput();
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setRole((prevRole) => ({
        ...prevRole,
        [name]: value,
      }));
    };
  
    const handleCreateRole = () => {
          if (!role.Code || !role.Name || !role.Description) {
               notify("Please fill in all fields!", 'error');
            return;
            }
  
      createRole(
        ApiUrls.roleApi,
        sessionToken,
        role,
        notify,
        setCustomMessage,
        setRole
      ).then(() => {
        // Reset tenant state and close modal after successful creation
        setRole({ Name: "", Code:"", Description:"" });
        handleClose(); // Close the modal on successful tenant creation
      });
    };

  return (
    <div>
       <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="Name"
              value={role.Name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Code"
              name="Code"
              value={role.Code}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Description"
              name="Description"
              value={role.Description}
              onChange={handleInputChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer >
        <div className="d-flex gap-2 w-100">       
          <Button className="bg-myColor" onClick={handleCreateRole}>
            Add Role
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
};
