import React, { useState } from "react";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { createTenant } from "../../DataService/TenantDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { Modal, Button } from "react-bootstrap"; // Import required components from react-bootstrap

export const CreateTenant = ({ show, handleClose }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [tenant, setTenant] = useState({ Name: "" });
  const { setCustomMessage } = useActiveInput();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTenant((prevTenant) => ({
      ...prevTenant,
      [name]: value,
    }));
  };

  const handleCreateTenant = () => {
    if (!tenant.Name) {
      notify("Please fill in the field!", 'error');
      return;
    }

    createTenant(
      ApiUrls.tenantApi,
      sessionToken,
      tenant,
      notify,
      setCustomMessage,
      setTenant
    ).then(() => {
      // Reset tenant state and close modal after successful creation
      setTenant({ Name: "" });
      handleClose(); // Close the modal on successful tenant creation
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Tenant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="Name"
              value={tenant.Name}
              onChange={handleInputChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex gap-2 w-100">   
          <Button className="bg-myColor" onClick={handleCreateTenant}>
            Add Tenant
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};
