import { useEffect, useState } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
} from "../../Utils/ReusableFunctions";
import { MDBTabs, MDBTabsItem, MDBTabsLink,MDBInput } from "mdb-react-ui-kit";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { updateTenant } from "../../DataService/TenantDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { useSearch } from "../../Context/SearchContext";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import{faPlus} from '@fortawesome/free-solid-svg-icons';
import { CreateTenant } from "./CreateTenant";


export const Tenant = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [tenantList, setTenantList] = useState([]);
  const {searchString} = useSearch();  // Use the search string from context
  const [selectedTenant, setSelectedTenant] = useState(null);

    //Show modal to create
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    // Fetch data again to refresh the list
    fetchData(ApiUrls.tenantApi, sessionToken).then((data) => {
      setTenantList(data.value);
    });
  };  
  const handleShow = () => setShowModal (true);


 const navigate = useNavigate();

 const [activeTab, setActiveTab] = useState('general'); 

 const handleTabClick = (tab) => {
   setActiveTab(tab);
   console.log('Tab clicked:', activeTab); // Debugging
 };

  // Set the first tenant as selected on component mount
  useEffect(() => {
   if (tenantList.length > 0) {
     setSelectedTenant(tenantList[0]); // Set the first role as selected
   }
 }, [tenantList]);

  const handleButtonClick = () => {
  //  navigate("/menu/createtenants");
  handleShow();
  };

  const { setCustomMessage } = useActiveInput();

  useEffect(() => {
    fetchData(ApiUrls.tenantApi, sessionToken).then((data) => {
      setTenantList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const filteredList = filterListByName(tenantList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedTenant,
        setSelectedTenant
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedTenant]);

  const handleChange = (field, value) => {
    setSelectedTenant((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  };

  const handleSave = () => {
    updateTenant(
      ApiUrls.tenantApi,
      sessionToken,
      selectedTenant,
      fetchData,
      setTenantList,
      notify,
      setCustomMessage
    );
  };

  return (
    <div className="container-fluid d-flex flex-column min-vh-100 mt-0 p-0">
  <div className="row">
    {/* Heading and Add Tenant Button */}
    <div className="row mb-0">
      <div className="col-lg-2 col-12 d-flex mb-0 p-0">
        <h5 className="mb-0 px-2">Tenants</h5>
        <button
          className="no-border d-flex ms-auto p-0"
          style={{ width: 'auto' }}
          onClick={handleButtonClick}
        >
          <FontAwesomeIcon
            icon={faPlus}
            className="myColor"
            size="2x"
            style={{ marginRight: '0.5rem', fontSize: '1.5rem' }} // Adjust font size as necessary
          />
        </button>
      </div>
  
          {/* Tabs (if a tenant is selected) */}
          {selectedTenant && (
            <div className="col-lg-10 col-md-12 col-sm-12 d-none d-md-flex mb-0 align-items-center">
              <MDBTabs fill className="mb-0">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleTabClick('general')}
                    active={activeTab === 'general'}
                    className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                  >
                    General
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
            </div>
          )}
        </div>
  
      <div className="row">
      {/* Tenant Table */}    
      <div className="col-lg-2 col-md-12 col-sm-12 mt-0 p-0"> 
        <div className="list-container no-spacing row g-0">
          <div className="table-responsive">
            <table className="table table-hover table-fixed">
              <tbody>
                {filteredList.map((item) => (
                  <tr
                    key={item.ID}
                    className={item.ID === selectedTenant?.ID ? "IdSelectedColor" : ""} // Keep the background shaded when the user is selected
                    onClick={() => setSelectedTenant(item)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>
                      <div className="info">                       
                        <div className="truncate-text text-start">{item.Name}</div> 
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>


                {/* Responsive Tabs Display for Small Screens */}
                <div className="row d-md-none">
            <div className="col-12">
              {selectedTenant && (
                <MDBTabs fill className="mb-0">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('general')}
                      active={activeTab === 'general'}
                      className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >
                      General
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('roles')}
                      active={activeTab === 'roles'}
                      className={`custom-tab-link ${activeTab === 'roles' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >                     
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              )}
            </div>
          </div>
  
          {/* Tenant Details and Tabs Content */}
          <div className="col-lg-5 col-md-12 col-sm-12 mt-3">
            {selectedTenant && (
              <div className="tab-content">
                {/* General Tab */}
                {activeTab === 'general' && (
                  <div className="tab-pane active">
                    <MDBInput
                      name="Name"
                      id="Name"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedTenant.Name}
                      onChange={(e) => handleChange("Name", e.target.value)}
                      label="Name"
                      type="text"
                      size="md"
                    />
                
                    <div>
                      <button
                        onClick={handleSave}
                        className="mb-3 w-100 btn bg-myColor"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />

      <CreateTenant show= {showModal} handleClose={handleClose}/>
    </div>
  );
}