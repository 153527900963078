import { useEffect, useState, useCallback } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
} from "../../Utils/ReusableFunctions";
import { MDBTabs, MDBTabsItem, MDBTabsLink,MDBInput } from "mdb-react-ui-kit";
import { AssignRoleModal } from "./AssignRoleModal";
import { ToastContainer } from "react-toastify";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify, getProperties } from "../../Utils/ReusableFunctions";
import { updateRole } from "../../DataService/RolesDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { useSearch } from "../../Context/SearchContext"; 
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import{faPlus} from '@fortawesome/free-solid-svg-icons';

import { CreateRole } from './CreateRole';  // Import your CreateRole modal

export const Roles = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [roleList, setRoleList] = useState([]);
  const {searchString} = useSearch();  // Use the search string from context
  const [selectedRole, setSelectedRole] = useState(null);

 // const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);

  //const handleShowCreateRoleModal = () => setShowCreateRoleModal(true);
  //const handleCloseCreateRoleModal = () => setShowCreateRoleModal(false);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    // Fetch tenant data again to refresh the list
    fetchData(ApiUrls.roleApi, sessionToken).then((data) => {
      setRoleList(data.value);
    });
  };
  const handleShow = () => setShowModal (true);
 
  const { setCustomMessage,  languageOptions  } = useActiveInput();
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('general'); 

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    console.log('Tab clicked:', activeTab); // Debugging
  };

   // Set the first role as selected on component mount
   useEffect(() => {
    if (roleList.length > 0) {
      setSelectedRole(roleList[0]); // Set the first role as selected
    }
  }, [roleList]);


  const handleButtonClick = () => {
  // navigate("/menu/createrole");
  handleShow();
  };
  
  const handleGetProperties = useCallback(() => {
    getProperties(ApiUrls.rolePropertiesApi, sessionToken, languageOptions, setProperties);
  }, [sessionToken, languageOptions]);

  const notifyError = (errorMessage) => {
    notify(errorMessage);
  };

  useEffect(() => {
    fetchData(ApiUrls.roleApi, sessionToken).then((data) => {
      setRoleList(data.value);
      console.log(data.value);

      handleGetProperties();
    });
  }, [sessionToken, handleGetProperties]);

  const filteredList = filterListByName(roleList, searchString);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(event, filteredList, selectedRole, setSelectedRole);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedRole]);

  const handleSave = () => {
    updateRole(
      ApiUrls.roleApi,
      sessionToken,
      selectedRole,
      fetchData,
      setRoleList,
      notify,
      setCustomMessage
    );
  };

  const handleChange = (field, value) => {
    setSelectedRole((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage('');
    }
  };

 return (
  <div className="container-fluid d-flex flex-column min-vh-100 mt-0 p-0">
  <div className="row">
    {/* Heading and Add Role Button */}
    <div className="row mb-0">
      <div className="col-lg-2 col-12 d-flex mb-0 p-0">
        <h5 className="mb-0 px-2">Roles</h5>
        <button
          className="no-border d-flex ms-auto p-0"
          style={{ width: 'auto' }}
          onClick={handleButtonClick}
      //  onClick={handleShowCreateRoleModal} 
        >
          <FontAwesomeIcon
            icon={faPlus}
            className="myColor"
            size="2x"
            style={{ marginRight: '0.5rem', fontSize: '1.5rem' }}
          />
        </button>
      </div>

          {/* column 2: Tabs (if a role is selected) */}
          {selectedRole && (
            <div className="col-lg-10 col-md-12 col-sm-12 d-none d-md-flex mb-0 align-items-center">
              <MDBTabs fill className="mb-0">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleTabClick('general')}
                    active={activeTab === 'general'}
                    className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                  >
                    General
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
            </div>
          )}
        </div>

        <div className="row">
          {/* Role Table */}
          <div className="col-lg-2 col-md-12 col-sm-12 mt-0 p-0">
          <div className="list-container no-spacing" style={{height:'48rem'}}>
          <div className="table-responsive">
            <table className="table table-striped table-hover" style={{ textAlign: 'left' }}>
              <tbody>
                {filteredList.map((item) => (
                  <tr
                    key={item.ID}
                    className={item.ID === selectedRole?.ID ? 'IdSelectedColor' : ''}
                    onClick={() => setSelectedRole(item)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>
                      <div className="d-block">
                        <h6 className="truncate-text">{item.Name}</h6>
                      </div>
                      <div className="d-block">Code: {item.Code}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div></div>

                {/* Responsive Tabs Display for Small Screens */}
                <div className="row d-md-none">
            <div className="col-12">
              {selectedRole && (
                <MDBTabs fill className="mb-0">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('general')}
                      active={activeTab === 'general'}
                      className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >
                      General
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('roles')}
                      active={activeTab === 'roles'}
                      className={`custom-tab-link ${activeTab === 'roles' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >                     
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              )}
            </div>
          </div>

          {/* Role Details and Tabs Content */}
          <div className="col-lg-5 col-md-12 col-sm-12 mt-3">
            {selectedRole && (
              <div className="tab-content">
                {/* General Tab */}
                {activeTab === 'general' && (
                  <div className="tab-pane active">
                    <MDBInput
                      name="Code"
                      id="Code"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedRole.Code}
                      onChange={(e) => handleChange('Code', e.target.value)}
                      label="Code"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Name"
                      id="Name"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedRole.Name}
                      onChange={(e) => handleChange('Name', e.target.value)}
                      label="Name"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Description"
                      id="Description"
                      wrapperClass="mb-2"
                      className="form-control"
                      value={selectedRole.Description}
                      onChange={(e) => handleChange('Description', e.target.value)}
                      label="Description"
                      type="text"
                      size="md"
                    />
                    <div className="d-flex gap-2">
                      <button onClick={handleSave}  className="mb-3 w-50 btn bg-myColor">
                        Save
                      </button>
                      <button
                        type="button"
                        className="mb-3 w-50 btn btn-secondary"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasAssignRole"
                        aria-controls="offcanvasAssignRole"
                      >
                        Assign role to user
                      </button>
                      <AssignRoleModal selectedRole={selectedRole} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <CreateRole show= {showModal} handleClose={handleClose}/>
     
     
    </div>
  );
}