import { useEffect, useState } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
} from "../../Utils/ReusableFunctions";
import { MDBTabs, MDBTabsItem, MDBTabsLink,MDBInput } from "mdb-react-ui-kit";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { updateLanguage } from "../../DataService/LanguageDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { useSearch } from "../../Context/SearchContext";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import{faPlus} from '@fortawesome/free-solid-svg-icons';
import { CreateLanguage } from "./CreateLanguage";

export const Languages = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const {searchString} = useSearch();  // Use the search string from context
  const [setSearchString] = useState("");
 
  const { setCustomMessage  } = useActiveInput();
  const filteredList = filterListByName(languageList, searchString);

      //Show modal to create
      const [showModal, setShowModal] = useState(false);
      const handleClose = () => {
        setShowModal(false);
        // Fetch data again to refresh the list
        fetchData(ApiUrls.langageApiApi, sessionToken).then((data) => {
          setLanguageList(data.value);
        });
      };  
      const handleShow = () => setShowModal (true);
    

  const [activeTab, setActiveTab] = useState('general'); 
  const navigate = useNavigate();

  const handleButtonClick = () => {
    //navigate("/menu/createlanguage");
    handleShow();
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    console.log('Tab clicked:', activeTab); // Debugging
  };

      // Set the first language as selected on component mount
      useEffect(() => {
        if (languageList.length > 0) {
          setSelectedLanguage(languageList[0]); // Set the first user as selected
        }
      }, [languageList])


  useEffect(() => {
    fetchData(ApiUrls.lanugageApi, sessionToken).then((data) => {
      setLanguageList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedLanguage,
        setSelectedLanguage
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedLanguage]);

  const handleSaveLanguage = () => {
    updateLanguage(ApiUrls.lanugageApi, sessionToken, selectedLanguage, fetchData, setLanguageList, notify, setCustomMessage);
  };

  const handleChange = (field, value) => {
    setSelectedLanguage((prevLanguage) => ({
      ...prevLanguage,
      [field]: value,
    }));
  };

  return (
    <div className="container-fluid d-flex flex-column min-vh-100 mt-0 p-0">
  <div className="row">
    {/* Heading and Add Language Button */}
    <div className="row mb-0">
      <div className="col-lg-2 col-12 d-flex mb-0 mt-0 p-0">
        <h5 className="mb-0 px-2">Languages</h5>
        <button
          className="no-border d-flex ms-auto p-0"
          style={{ width: 'auto' }}
          onClick={handleButtonClick}
        >
          <FontAwesomeIcon
            icon={faPlus}
            className="myColor"
            size="2x"
            style={{ marginRight: '0.5rem', fontSize: '1.5rem' }}
          />
        </button>
      </div>

           {/* column 2: Tabs (if a language is selected) */}
           {selectedLanguage && (
          <div className="col-lg-10 col-md-12 col-sm-12 d-none d-md-flex mb-0 align-items-center">
            <MDBTabs fill className="mb-0">
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleTabClick('general')}
                  active={activeTab === 'general'}
                  className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                  style={{ textTransform: 'none', fontSize: '1rem' }}
                >
                  General
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
          </div> 
        )}
          </div>
                  
          <div className="row">
          {/* Language Table */}
      <div className="col-lg-2 col-md-12 col-sm-12 mt-0 p-0">
        <div className="list-container no-spacing row g-0" style={{height:'48rem'}}>
          <div className="table-responsive">
            <table className="table table-hover table-fixed">
              <tbody>
                {filteredList.map((item) => (
                  <tr
                    key={item.ID}
                    className={item.ID === selectedLanguage?.ID ? "IdSelectedColor" : ""} // keep the background shaded when the user is selected
                    onClick={() => setSelectedLanguage(item)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>
                      <div className="info">
                        <h6 className="truncate-text text-start">{item.Name}</h6>
                        <div className="truncate-text text-start">Code: {item.Code}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div></div>

              {/* Responsive Tabs Display for Small Screens */}
                <div className="row d-md-none">
            <div className="col-12">
              {selectedLanguage && (
                <MDBTabs fill className="mb-0">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('general')}
                      active={activeTab === 'general'}
                      className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >
                      General
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('roles')}
                      active={activeTab === 'roles'}
                      className={`custom-tab-link ${activeTab === 'roles' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >                     
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              )}
            </div>
          </div>


    {/* Language Details */}
    <div className="col-lg-5 col-md-12 col-sm-12 mt-3">
      {selectedLanguage && (
     <div className="tab-content">
            {/*General Tab */}   
            {activeTab === 'general' && (
              <div className="tab-pane active">
          <MDBInput
            name="Code"
            id="Code"
            wrapperClass="mb-3"
            className="form-control"
            value={selectedLanguage.Code}
            onChange={(e) => handleChange("Code", e.target.value)}
            label="Code"
            type="text"
            size="md"
          />
          <MDBInput
            name="Name"
            id="Name"
            wrapperClass="mb-3"
            className="form-control"
            value={selectedLanguage.Name}
            onChange={(e) => handleChange("Name", e.target.value)}
            label="Name"
            type="text"
            size="md"
          />
          <div>
            <button
              onClick={handleSaveLanguage}
              className="mb-3 w-100 btn bg-myColor"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
        )}
   </div>
          </div>
          </div>
  <ToastContainer />
  <CreateLanguage show= {showModal} handleClose={handleClose}/>
  
  </div>

);};