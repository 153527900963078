import { SideBar } from "./SideBar";
import { InfoBarBottom } from "./InfoBarBottom";
import { NavigationBar } from "./NavigationBar";
import { ActiveInputProvider } from "../../Context/ActiveInputTooltip";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


export const ApplicationContainer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const sessionToken = localStorage.getItem("bearerToken");

    // If the session token is not present, redirect to the login page
    if (!sessionToken) {
      navigate("/signin");
    }
  }, [navigate]);

    return (
        <ActiveInputProvider>
          <NavigationBar />       
          <InfoBarBottom />
        </ActiveInputProvider>
    );
  };