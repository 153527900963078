import React, { useState } from "react";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { createCountry } from "../../DataService/CountryDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { toast } from 'react-toastify';
import { Modal, Button } from "react-bootstrap"; 

export const CreateCountry = ({ show, handleClose }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [country, setCountry] = useState({
    Alpha3: "",
    Code: "",
    Name: "",
    Number: "",
    Translations: null,
  });
  const { setCustomMessage } = useActiveInput();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCountry((prevCountry) => ({
      ...prevCountry,
      [name]: value,
    }));
  };

  const handleCreateCountry = () => {
    if (!country.Code || !country.Alpha3 || !country.Name || !country.Number) {
      notify("Please fill in all fields!", 'error');
      return;
    }

    createCountry(
      ApiUrls.countryApi,
      sessionToken,
      country,
      notify,  // Using notify function 
      setCustomMessage,
      setCountry
    ).then(() => {
      // Reset country state and close modal after successful creation
      setCountry({Code:"", Alpha3:"", Name: "",Number:"" });
      handleClose(); // Close the modal on successful tenant creation
    });;
  };

  return (
    <>
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Country</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Code"
            name="Code"
            value={country.Code}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Alpha3"
            name="Alpha3"
            value={country.Alpha3}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="Name"
            value={country.Name}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-2 ">
          <input
            type="number"
            className="form-control"
            placeholder="Number"
            name="Number"
            value={country.Number}
            onChange={handleInputChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
      <div className="d-flex gap-2 w-100">   
        <button className="btn bg-myColor w-100" onClick={handleCreateCountry}>
          Add Country
        </button>
        <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
      
      <ToastContainer />
    </>
  );
};
