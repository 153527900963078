export const updateTenant = async (
  apiUrl,
  sessionToken,
  selectedTenant,
  fetchData,
  setTenantList,
  notify,
  setCustomMessage
) => {
  try {
    if (selectedTenant) {
      const patchUrl = `${apiUrl}(${selectedTenant.ID})`;
      const patchData = {
        Name: selectedTenant.Name,
      };

      const response = await fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      });

      if (response.ok) {
        console.log("Tenant updated successfully");
        setCustomMessage("Tenant updated");
        const data = await fetchData(apiUrl, sessionToken);
        setTenantList(data.value);
        notify("Tenant saved successfully!", 'success'); // Notify success
      } else {
        notify("Error updating tenant");
        console.error("Error updating tenant. Status:", response.status);
      }
    }
  } catch (error) {
    notify("Error updating tenant");
    console.error("Error updating tenant:", error);
  }
};

export const createTenant = async (
  apiUrl,
  sessionToken,
  tenant,
  notify,
  setCustomMessage,
  setTenant
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(tenant),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Tenant created:", data);
      setCustomMessage("Tenant created");
      setTenant({
        Name: "",
      });
      notify("Tenant added successfully!", 'success'); // Notify success
    } else {
      const errorMessage = await response.text(); // Get error message from response
      notify(`Error creating tenant: ${errorMessage}`, 'error'); // Notify error
      throw Error("Failed to create tenant");
    }
  } catch (error) {
    notify("Error creating tenant");
    console.error("Error creating tenant:", error);
  }
};
