import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { createApplication } from "../../DataService/ApplicationDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";

export const CreateApplication = ({ show, handleClose }) => {
  const sessionToken = localStorage.getItem("bearerToken");   
  const [application, setApplication] = useState({  
    Name: "",
    Code: "",
    Url: "",});
  const { setCustomMessage } = useActiveInput();

  const handleInputChange = (e) => {
    const { name, value } = e.target;  
    setApplication((prevApplication) => ({
      ...prevApplication,
      [name]: value,
      
    }));
  };

  const handleCreateApplication = () => {
    if (!application.Name || !application.Code || !application.Url) {
      notify("Please fill in all fields!", 'error');
      return;
    }
  createApplication(
    ApiUrls.applicationApi,
    sessionToken,
    application, 
      notify,
      setCustomMessage,
      setApplication).then(() => {
        // Reset app state and close modal after successful creation
        setApplication({ Name: "",
          Code: "",
          Url: "" });
        handleClose(); // Close the modal on successful tenant creation
      })
      .catch((error) => {
        console.error("Error creating application:", error);
        // Handle any additional error logic here
      });
      console.log("Creating application with data:", application);
  };

 

  const [selectedApplication, setSelectedApplication] = useState("");
  useEffect(() => {

  }, [sessionToken]);
  

  
  const handleChange = (event) => {
    setSelectedApplication(event.target.value);
  };


  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="Name"
              value={application.Name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Code"
              name="Code"
              value={application.Code}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Url"
              name="Url"
              value={application.Url}
              onChange={handleInputChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex gap-2 w-100">   
        <Button className="bg-myColor" onClick={handleCreateApplication}>
            Add Application
          </Button>       
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>         
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  );
};
