import { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { createApiKey } from "../../DataService/ApiKeyDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { Modal, Button } from "react-bootstrap"; 

export const CreateApikey = ({ show, handleClose }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [userList, setUserList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [apikey, setApikey] = useState({
    UserID: "",
    ApplicationID: "",
    Key: "",
    UseData: "",
  });
  const { setActiveInput, setCustomMessage } = useActiveInput();

  useEffect(() => {
    fetchData(ApiUrls.userApi, sessionToken).then((data) => {
      setUserList(data.value);

    });
    fetchData(ApiUrls.applicationApi, sessionToken).then((data) => {
      setApplicationList(data.value);
      console.log(data.value);
    });
  }, [sessionToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApikey((prevLanguage) => ({
      ...prevLanguage,
      [name]: value,
    }));
  };

  const handleCreateApiKey = () => {  
    console.log(apikey);
    createApiKey(
      ApiUrls.apikeyApi,
      sessionToken,
      apikey,
      notify,
      setActiveInput,
      setApikey,
      setCustomMessage
    ).then(() => {
      // Reset tenant state and close modal after successful creation
      setApikey({ UserID: "",
        ApplicationID: "",
        Key: "",
        UseData: "" });
      handleClose(); // Close the modal on successful tenant creation
    });;
  };

  const handleUserChange = (event) => {
    setApikey({
      ...apikey,
      UserID: parseInt(event.target.value, 10), // Convert to integer
    });
  };

  const handleApplicationChange = (event) => {
    setApikey({
      ...apikey,
      ApplicationID: parseInt(event.target.value, 10), // Convert to integer
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create an API Key</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="mb-3">
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={handleUserChange}
            value={apikey.UserID}
          >
            <option value="" disabled>
              Choose User
            </option>
            {userList.map((user) => (
              <option key={user.ID} value={user.ID}>
                {user.Name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-2">
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={handleApplicationChange}
            value={apikey.ApplicationID}
          >
            <option value="" disabled>
              Choose application
            </option>
            {applicationList.map((application) => (
              <option key={application.ID} value={application.ID}>
                {application.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group mb-2 ">
          <input
            type="text"
            className="form-control"
            placeholder="Key"
            name="Key"
            value={apikey.Key}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-2 ">
          <input
            type="text"
            className="form-control"
            placeholder="UseData"
            name="UseData"
            value={apikey.UseData}
            onChange={handleInputChange}
          />
        </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex gap-2 w-100">   
          <Button className="bg-myColor" onClick={handleCreateApiKey}>
          Create api key
        </Button>
        <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
      
      <ToastContainer />
    </>
  );
};
