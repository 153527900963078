import React, { useState } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import AsyncSelect from "react-select";
import { createUser } from "../../DataService/UsersDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { Modal, Button } from "react-bootstrap"; 

export const CreateUser = ({ show, handleClose }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [tenantList, setTenantList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [user, setUser] = useState({
    Username: "",
    Active: true,
    TenantID: "",
    Name: "",
  });

  const { setActiveInput } = useActiveInput();

  
  const getTenants = () => {
    fetchData(ApiUrls.tenantApi, sessionToken).then((data) => {
      setTenantList(data.value);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    setUser((prevUser) => ({
      ...prevUser,
      TenantID: selectedOption.value,
    }));
  };

  const handleCreateUser = () => {
    console.log("Creating user with data: ", user);
    createUser(
      ApiUrls.userApi,
      sessionToken,
      user,
      notify,
    //  setActiveInput,
      setUser,
      setSelectedValue
    ).then(() => {
      // Reset user state and close modal after successful creation
      setUser({ Name: "" , Username:"", TenantID: ""});
      handleClose(); // Close the modal on successful user creation
    });;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>       
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Email  "
            name="Username"
            value={user.Username}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-3 ">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="Name"
            value={user.Name}
            onChange={handleInputChange}
          />
        </div>
        <AsyncSelect
          className="mb-2"
          id="tenantSelect"
          value={selectedValue}
          onChange={handleChange}
          options={tenantList.map((tenant) => ({
            value: tenant.ID,
            label: tenant.Name,
          }))}
          onMenuOpen={() => {
            getTenants();
          }}
          styles={{
            control: (provided) => ({
              ...provided,
              textAlign: "left",
            }),
            option: (provided) => ({
              ...provided,
              textAlign: "left",
            }),
          }}
        />
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex gap-2 w-100">   
        <Button className="bg-myColor w-100" onClick={handleCreateUser}>
          Create User
        </Button>
        <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </div>
        </Modal.Footer>
        </Modal>
      
      <ToastContainer />
    </>
  );
};
