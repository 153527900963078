export const ApiUrls = {

  apikeyApi: "https://auth.decentra.net/odata/ApiKey", 
  applicationApi: "https://auth.decentra.net/odata/Application",
  userApplicationApi: "https://auth.decentra.net/odata/UserApplication",
  userAssignApplicationApi:  "https://auth.decentra.net/odata/userapplication", //assign app to user
  filteredApplicationApi: "https://auth.decentra.net/odata/application?$filter=not(UserAssignments/any(a: a/UserID eq 1))",
  deleteUserApplicationtApi:"https://auth.decentra.net/odata/UserApplication/",
  getUserWithApplicationApi: "https://auth.decentra.net/odata/User?$expand=applicationassignments($expand=Application($select=Code, Description))",

  filteredRoleApi: "https://auth.decentra.net/odata/role?$filter=not(UserAssignments/any(a: a/UserID eq 1))",
  userAssignRolesApi: "https://auth.decentra.net/odata/role?$filter=UserAssignments/",
  userAssignApplicationsApi: "https://auth.decentra.net/odata/application?$filter=UserAssignments/",
  deleteUserRoleAssignmentApi:"https://auth.decentra.net/odata/userrole/",
  deleteUserApplicationAssignmentApi:"https://auth.decentra.net/odata/userapplication/",

  countryApi: "https://auth.decentra.net/odata/Country",
  lanugageApi: "https://auth.decentra.net/odata/Language",
  roleApi: "https://auth.decentra.net/odata/Role",  //assign role to user
  userRoleApi: "https://auth.decentra.net/odata/UserRole", 
  tenantApi: "https://auth.decentra.net/odata/Tenant",
  getUserWithRoleApi: "https://auth.decentra.net/odata/User?$expand=roleassignments($expand=Role($select=Code,Name,Description))",
  userApi: "https://auth.decentra.net/odata/User",
  currencyApi:  "https://auth.decentra.net/odata/Currency",
  applicationPropertiesAPi: "https://auth.decentra.net/odata/Application/Properties",
  apikeyPropertiesApi: "https://auth.decentra.net/odata/ApiKey/properties",
  countryPropertiesApi: "https://auth.decentra.net/odata/Country/Properties",
  userPropertiesApi:  "https://auth.decentra.net/odata/User/Properties",
  userRolePropertiesApi:  "https://auth.decentra.net/odata/UserRole/Properties",
  tenantPropertiesApi:  "https://auth.decentra.net/odata/Tenant/Properties",
  rolePropertiesApi:  "https://auth.decentra.net/odata/Role/Properties",
  languagePropertiesApi:  "https://auth.decentra.net/odata/Language/Properties",
  currencyPropertiesApi:  "https://auth.decentra.net/odata/Currency/Properties",
  userMFAApi: "https://auth.decentra.net/odata/UserMFA",

  

  

};
