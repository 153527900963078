import React, { useState, useEffect } from 'react';
import { assignApplicationToUser, filterUnassignedApplications, getUserApplications, getUserAssignedApplications } from "../../DataService/ApplicationDataService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserTag } from '@fortawesome/free-solid-svg-icons';
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { notify } from "../../Utils/ReusableFunctions";
import { toast } from 'react-toastify';

const AddApplicationAssignments = ({ selectedUser, refreshUserList }) => {
  const [userApplications, setUserApplications] = useState([]);
  const [assignedApplications, setAssignedApplications] = useState([]);
  const [availableApplications, setAvailableApplications] = useState([]);
  const [selectedApplicationIds, setSelectedApplicationIds] = useState([]);
  const sessionToken = localStorage.getItem("bearerToken");

  useEffect(() => {
    const offcanvasElement = document.getElementById('offcanvasApplicationAssignments');
    const savedWidth = localStorage.getItem('offcanvasWidth') || '30%';

    const updateOffcanvasWidth = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 576) {
        offcanvasElement.style.width = '100%';
      } else if (screenWidth < 768) {
        offcanvasElement.style.width = '60%';
      } else {
        offcanvasElement.style.width = savedWidth;
      }
    };

    offcanvasElement.addEventListener('show.bs.offcanvas', updateOffcanvasWidth);
    window.addEventListener('resize', updateOffcanvasWidth);

    return () => {
      offcanvasElement.removeEventListener('show.bs.offcanvas', updateOffcanvasWidth);
      window.removeEventListener('resize', updateOffcanvasWidth);
    };
  }, []);


  useEffect(() => {
    // Fetch user applications
    getUserApplications(ApiUrls.applicationApi, sessionToken, notify, (data) => {
      setUserApplications(Array.isArray(data) ? data : []);
    });
  
    // Fetch assigned applications for the selected user
    if (selectedUser) {
      getUserAssignedApplications(selectedUser.ID, sessionToken, notify, (data) => {
        const assignedApps = Array.isArray(data) ? data : [];
        setAssignedApplications(assignedApps);
        console.log("Assigned applications from useEffect:", assignedApps); // Log assigned applications
        console.log("Assigned App IDs:", assignedApps.map(app => app.ID)); // Log assigned App IDs
       
      });
    }
  }, [sessionToken, selectedUser]);
  
  useEffect(() => {
    if (userApplications.length > 0 && assignedApplications.length > 0) {    
      const assignedAppIds = new Set(assignedApplications.map(app => String(app.ApplicationID)));
  
      const filteredApps = userApplications.filter(app => {
        const isAssigned = assignedAppIds.has(String(app.ID)); // IDs are compared as strings
        console.log(`Checking app ID: ${app.ID}, Assigned: ${isAssigned}, Assigned App IDs: ${Array.from(assignedAppIds)}`);
        return !isAssigned; // Filter out assigned applications
      });
  
      setAvailableApplications(filteredApps);    
    }
  }, [userApplications, assignedApplications]); 



  const handleCheckboxChange = (appId) => {
    setSelectedApplicationIds(prevSelected =>
      prevSelected.includes(appId)
        ? prevSelected.filter(id => id !== appId)
        : [...prevSelected, appId]
    );
  };

  const handleAddApplicationtoUser = async () => {
    if (selectedUser && selectedApplicationIds.length > 0) {
      const appsToAssign = selectedApplicationIds
        .map(appId => availableApplications.find(app => app.ID === appId))
        .filter(Boolean);
  
      // Loop over applications to assign them
      for (const selectedApp of appsToAssign) {
        await assignApplicationToUser(
          ApiUrls.userApplicationApi,
          sessionToken,
          selectedUser.ID,
          selectedApp.ID,
          notify,
          (message) => {
            console.log(`Application ${selectedApp.DisplayName} added:`, message);
          }
        );
      }
  
      // Update state after all assignments
      setAssignedApplications(prevAssigned => [
        ...prevAssigned,
        ...appsToAssign,
      ]);
  
      setAvailableApplications(prevAvailable =>
        prevAvailable.filter(app => !selectedApplicationIds.includes(app.ID))
      );
  
      // Clear selected applications and show success message
      setSelectedApplicationIds([]);
      refreshUserList();
      toast.success("Applications assigned successfully");
      
    } else {
      notify("Please select at least one application to assign.");
    }
  };
  

  return (
    <div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="text-start" style={{ width: '3%' }}></th>
              <th className="text-start" style={{ width: '60%', fontWeight: 'bold' }}>Application</th>
              <th className="text-start" style={{ width: '40%', fontWeight: 'bold' }}>Application URL</th>
            </tr>
          </thead>
          <tbody>
            {availableApplications.length > 0 ? (
              availableApplications.map((app) => (
                <tr key={app.ID}>
                  <td className="text-start">
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(app.ID)}
                      checked={selectedApplicationIds.includes(app.ID)}
                    />
                  </td>
                  <td className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faUserTag}
                      className="myColor"
                      style={{ marginRight: '0.5rem' }}
                    />
                    <span>{app.DisplayName || 'No Name Available'}</span>
                  </td>
                  <td className="text-start">{app.Url || 'Link is not Available'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  No available applications found for this user.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-start">
        <button
          className="btn bg-myColor mt-3"
          style={{ width: '100px' }}
          data-bs-dismiss="offcanvas"
          onClick={handleAddApplicationtoUser}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.5rem' }} />
          Add 
        </button>
      </div>
    </div>
  );
};

export default AddApplicationAssignments;
