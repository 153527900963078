import React, { useState } from "react";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { notify } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { createCurrency } from "../../DataService/CurrencyDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { Modal, Button } from "react-bootstrap"; // Import required components from react-bootstrap


export const CreateCurrency = ({ show, handleClose }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [currency, setCurrency] = useState({
    Code: "",
    Name: "",
  });
  const { setActiveInput, setCustomMessage } = useActiveInput();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrency((prevCurrency) => ({
      ...prevCurrency,
      [name]: value,
    }));
  };

  const handleCreateCurrency = () => {
    if (!currency.Code || !currency.Name) {
      notify("Please fill in all fields!", 'error');
      return;
    }
    createCurrency(
      ApiUrls.currencyApi,
      sessionToken,
      currency,
      notify,     
      setCustomMessage,
      setCurrency
    ).then(() => {
      // Reset currency state and close modal after successful creation
      setCurrency({Code:"", Name: "" });
      handleClose(); // Close the modal on successful tenant creation
    });;
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Currency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Code"
            name="Code"
            value={currency.Code}
            onChange={handleInputChange}
          />
        </div>
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            name="Name"
            value={currency.Name}
            onChange={handleInputChange}
          />
        </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex gap-2 w-100"> 
        <Button className="bg-myColor"onClick={handleCreateCurrency}>
          Create currency
        </Button>
        <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
      
      <ToastContainer />
    </>
  );
};
