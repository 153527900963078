import { ApiUrls } from "./ApiUrlDataService";

export const updateRole = async (
  apiUrl,
  sessionToken,
  selectedRole,
  fetchData,
  setRoleList,
  notify,
  setCustomMessage
) => {
  try {
    if (selectedRole) {
      const patchUrl = `${apiUrl}(${selectedRole.ID})`;
      const patchData = {
        Code: selectedRole.Code,
        Name: selectedRole.Name,
        Description: selectedRole.Description,
      };

      const response = await fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      });

      if (response.ok) {
        console.log("Role updated successfully");
        setCustomMessage("Role updated");
        const data = await fetchData(apiUrl, sessionToken);
        setRoleList(data.value);
        notify("Role updated successfully!", 'success'); // Notify success
      } else {
        console.error("Error updating role. Status:", response.status);
        notify("Error updating role");
      }
    }
  } catch (error) {
    console.error("Error updating role:", error);
    notify("Error updating role");
  }
};

//to create role
export const createRole = async (
  apiUrl,
  sessionToken,
  role,
  notify,
  setCustomMessage,
  setRole
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(role),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Role created:", data);
      setCustomMessage("Role created");
      setRole({
        Name: "",
        Code: "",
        Description: "",
      });
      notify("Role added successfully!", 'success'); // Notify success
    } else {
      const errorMessage = await response.text(); // Get error message from response
      notify(`Error creating role: ${errorMessage}`, 'error'); // Notify error
      throw Error("Failed to create role");
    }
  } catch (error) {
    notify("Error creating role");
    console.error("Error creating role:", error);
  }
};

//to assign role to user
export const assignRoleToUser = async (
  apiUrl,
  sessionToken,
  selectedUserId,
  selectedRoleId,
  notify,
  setCustomMessage
) => {
  try {
    if (selectedUserId && selectedRoleId) {
      const assignRoleApi = `${apiUrl}`;
      const assignData = {
        userId: selectedUserId,
        roleId: selectedRoleId,
      };

      const response = await fetch(assignRoleApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(assignData),
      });

      if (response.ok) {
        setCustomMessage("Role assigned");       
      
        console.log("Role assigned to user successfully");
       // notify("Role assigned successfully"); // Display success message
      } else {
        console.error("Error assigning role to user. Status:", response.status);
      //  notify("Error assigning role to user");
      }
    }
  } catch (error) {
    console.error("Error assigning role to user:", error);
  //  notify("Error assigning role to user");
  }
};





//GEt filtered roles where role list doesn't contain System Admin
export const getFilteredRoles = async (
  apiUrl,
  excludedRoleIds,
  sessionToken,
  notify,
  setRoleList
) => {
  try {
    // Fetch the list of UserRole entries
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      const Roles = data.value || [];


    


      // If no roles to exclude, just return all roles
      if (excludedRoleIds.length === 0) {
        setRoleList(Roles);
        return;
      }

      // Fetch all roles
      const allRolesResponse = await fetch(ApiUrls.filteredRoleApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (allRolesResponse.ok) {
        const allRolesData = await allRolesResponse.json();
        const allRoles = allRolesData.value || [];

        // Filter roles excluding those assigned to UserID
        const filteredRoles = allRoles.filter(role => !excludedRoleIds.includes(role.ID));

        // Update role list state with filtered roles
        setRoleList(filteredRoles);
      } else {
        console.error("Error fetching all roles:", allRolesResponse.status);
        notify("Error fetching all roles");
      }
    } else {
      console.error("Error fetching user-role assignments:", response.status);
      notify("Error fetching user-role assignments");
    }
  } catch (error) {
    console.error("Error fetching roles:", error);
    notify("Error fetching roles");
  }
};

// Fetch roles assigned to a user
export const getUserAssignedRoles = async (userId, sessionToken, notify, setAssignedRoles) => {
  let api = `${ApiUrls.userAssignRolesApi} any(a:a/UserID eq ${userId})` 
  try {
    const response = await fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`
      }
    });

    if (response.ok) {
      const data = await response.json();
            // Ensure data is an array
        setAssignedRoles(data.value);
       
    } else {
      console.error("Error fetching assigned roles. Status:", response.status);
      notify("Error fetching assigned roles.");
    }
  } catch (error) {
    console.error("Error fetching assigned roles:", error);
    notify("Error fetching assigned roles.");
  }
};


// Delete assigned roles
export const deleteUserAssignedRoles = async (selectedRoleIds, sessionToken, notify, setAssignedRoles, userId) => {
  if (!selectedRoleIds || selectedRoleIds.length === 0) {
    notify("No roles selected for deletion.");
    return;
  }

  // Show confirmation dialog
  const confirmDelete = window.confirm("Are you sure you want to delete the selected roles?");
  if (!confirmDelete) {
    // User canceled the deletion
    return;
  }

  try {
    // Loop through each selected role ID and send a DELETE request
    for (const roleId of selectedRoleIds) {
      const api = `${ApiUrls.deleteUserRoleAssignmentApi}${roleId}`;
      
      const response = await fetch(api, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`
        }
      });

      if (!response.ok) {
        console.error(`Error deleting role ID ${roleId}. Status:`, response.status);
     //  notify(`Error deleting role ID ${roleId}.`);
        return;
      }
    }


  } catch (error) {
    console.error("Error deleting roles:", error);
    notify("Error deleting roles.");
  }
};