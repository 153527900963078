export const createCurrency = async (
  apiUrl,
  sessionToken,
  currency,
  notify,
  setCustomMessage,
  setCurrency
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(currency),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Currency created:", data);
      setCustomMessage("Currency created");
      setCurrency({
        Code: "",
        Name: "",
      });
      notify("Currency added successfully!", 'success'); // Notify success
    } else {
      const errorMessage = await response.text(); // Get error message from response
      notify(`Error creating currency: ${errorMessage}`, 'error'); // Notify error
      throw Error("Failed to create currency");
    }
  } catch (error) {
    notify("Error creating currency");
    console.error("Error creating currency:", error);
  }
};

export const updateCurrency = async (
  apiUrl,
  sessionToken,
  selectedCurrency,
  fetchData,
  setCurrencyList,
  notify,
  setCustomMessage
) => {
  try {
    if (selectedCurrency) {
      const patchUrl = `${apiUrl}(${selectedCurrency.ID})`;
      const patchData = {
        Code: selectedCurrency.Code,
        Name: selectedCurrency.Name,
      };

      const response = await fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      });

      if (response.ok) {
        console.log("Currency updated successfully");
        setCustomMessage("Currency updated successfully");
        const data = await fetchData(apiUrl, sessionToken);
        setCurrencyList(data.value);
        notify("Currency saved successfully!", 'success'); // Notify success
      } else {
        notify("Error updating currency");
        console.error("Error updating currency. Status:", response.status);
      }
    }
  } catch (error) {
    notify("Error updating currency");
    console.error("Error updating currency:", error);
  }
};
