import { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { ToastContainer } from "react-toastify";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { assignRoleToUser } from "../../DataService/RolesDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { notify } from "../../Utils/ReusableFunctions";
import { Roles } from "./Roles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faUser} from '@fortawesome/free-solid-svg-icons';

export const AssignRoleModal = ({
    selectedRole,
    notifyError 
  }) => {
    const sessionToken = localStorage.getItem("bearerToken");
    const [userList, setUserList] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
       // State to track selected role IDs
      const [selectedUserIds, setSelectedUserIds] = useState([]);
    const { setCustomMessage } = useActiveInput();
  
    useEffect(() => {
      fetchData(ApiUrls.userApi, sessionToken).then((data) => {
        setUserList(data.value);
        console.log(data.value);
      });
    }, [sessionToken]);
  
    /* const handleUserChange = (event) => {
      setSelectedUserId(event.target.value);
    }; */
  
   // Function to handle checkbox change
   const handleCheckboxChange = (userId) => {
    setSelectedUserIds((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId) // Uncheck
        : [...prev, userId] // Check
    );
  };
  
  
    const handleAssignRole = () => {
      selectedUserIds.forEach((userId) => {
      assignRoleToUser(
        ApiUrls.userRoleApi,
        sessionToken,
        userId,
        selectedRole.ID,
        notify,
        setCustomMessage
      );
    });
  };
  
    return (
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasAssignRole"
        aria-labelledby="offcanvasAssignRoleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasAssignRoleLabel">
            Assign role to user
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"    
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="mb-2" style={{ textAlign: "left" }}>
          Role Name:  {selectedRole.Name}
          </div>
          <table className="table-container">
            <thead>
              <tr>
              <th className="text-start" style={{ width: '3%' }}></th>
              <th className="text-start" style={{ width: '40%', fontWeight: 'bold' }}>User Name</th>    
              </tr>
            </thead>
            <tbody>
              {userList.map((user) => (
                <tr key={user.ID}>
                   <td className="text-start">
                <input
                        type="checkbox"                    
                        onChange={() => handleCheckboxChange(user.ID)} // Call handler on change
                        checked={selectedUserIds.includes(user.ID)} // Keep checkbox checked state synced
                      />
                </td>
                <td className="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon={faUser} // Role icon
                    className="myColor" // Custom color
                    style={{ marginRight: '0.5rem' }}
                  />
                  <span>{user.Name || 'No User Available'}</span> {/* USer name */}
                </td>              
                 
                </tr>
                
              ))}
              
            </tbody>
          </table>
        </div>
  
        <div className="offcanvas-footer d-flex justify-content-start m-3">
        <button
            type="button"
            className="btn bg-myColor mt-3"            
            onClick={handleAssignRole}
            data-bs-dismiss="offcanvas"   
          >
            Assign Role
          </button>
          
        </div>
      </div>
    );
  };
  