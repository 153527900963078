import { Outlet, Navigate } from "react-router-dom";
import { useCheckToken } from "../../Utils/useCheckToken";
import { toast } from "react-toastify"; 

export const PrivateRoute = () => {
  
  const isTokenExpired = useCheckToken(); // Token expiration check

  if (isTokenExpired) {
    toast.error("Session expired, please sign in again."); // Show error toast
    return <Navigate to="/signin" state={{ message: "Session expired, please sign in again." }} />;
  }

  return <Outlet />; // Render protected components if token is valid
};
