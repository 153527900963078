import { useState, useEffect, useCallback } from "react";
import {
  fetchData,
  handleListNavigation,
  notify,
  getProperties,
} from "../../Utils/ReusableFunctions";
import { MDBTabs, MDBTabsItem, MDBTabsLink,MDBInput } from "mdb-react-ui-kit";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { ToastContainer } from "react-toastify";
import { updateApikey } from "../../DataService/ApiKeyDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { useSearch } from "../../Context/SearchContext";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import{faPlus} from '@fortawesome/free-solid-svg-icons';
import { CreateApikey } from "./CreateApikey";

export const ApiKeys = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [apikeyList, setApikeyList] = useState([]);
  const [selectedApikey, setSelectedApikey] = useState(null);
  const {searchString} = useSearch();  // Use the search string from context
  /*const [searchString, setSearchString] = useState("");*/
  const {languageOptions, setCustomMessage } = useActiveInput();
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

   //Show modal to create
   const [showModal, setShowModal] = useState(false);
   const handleClose = () => {
     setShowModal(false);
     // Fetch data again to refresh the list
     fetchData(ApiUrls.apikeyApi, sessionToken).then((data) => {
       setApikeyList(data.value);
     });
   };  
   const handleShow = () => setShowModal (true);
 

  const [activeTab, setActiveTab] = useState('general'); 

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    console.log('Tab clicked:', activeTab); // Debugging
  };

   // Set the first apikey as selected on component mount
   useEffect(() => {
    if (apikeyList.length > 0) {
      setSelectedApikey(apikeyList[0]); // Set the first role as selected
    }
  }, [apikeyList]);

  const handleButtonClick = () => {
    //navigate("/menu/createapikey");
    handleShow();
  };

  const handleGetProperties = useCallback(() => {
    getProperties(ApiUrls.apikeyPropertiesApi, sessionToken, languageOptions, setProperties);
  }, [sessionToken, languageOptions]);

  useEffect(() => {
    fetchData(ApiUrls.apikeyApi, sessionToken).then((data) => {
      setApikeyList(data.value);
      console.log(data.value);
    });

    handleGetProperties();
  }, [sessionToken, handleGetProperties]);

  const filteredList = apikeyList.filter((item) =>
    item.UseData.toLowerCase().includes(searchString.toLowerCase())
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedApikey,
        setSelectedApikey
      );
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedApikey]);

  const handleSave = () => {
    if (selectedApikey) {
      updateApikey(
        ApiUrls.apikeyApi,
        sessionToken,
        selectedApikey,
        fetchData,
        setApikeyList,
        notify,
        setCustomMessage
      );
    }
  };

  const handleChange = (field, value) => {
    setSelectedApikey((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage('');
    }
  };



  return (
    <div className="container-fluid d-flex flex-column min-vh-100 mt-0 p-0">
    <div className="row">
      {/* Heading and Add API Key Button */}
      <div className="row mb-0">
        <div className="col-lg-2 col-12 d-flex mb-0 p-0">
          <h5 className="mb-0 px-2">API Keys</h5>
          <button
            className="no-border d-flex ms-auto p-0"
            style={{ width: 'auto' }}
            onClick={handleButtonClick}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="myColor"
              size="2x"
              style={{ marginRight: '0.5rem', fontSize: '1.5rem' }} // Adjust font size as necessary
            />
          </button>
        </div>
  
          {/* Tabs (if an API key is selected) */}
          {selectedApikey && (
            <div className="col-lg-10 col-md-12 col-sm-12 d-none d-md-flex mb-0 align-items-center">
              <MDBTabs fill className="mb-0">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleTabClick('general')}
                    active={activeTab === 'general'}
                    className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                    style={{ textTransform: 'none', fontSize: '1rem' }}
                  >
                    General
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
            </div>
          )}
        </div>
  
        <div className="row">
        {/* API Key Table */}
        <div className="col-lg-2 col-md-12 col-sm-12 mt-0 p-0"> {/* Adjust width if necessary */}
          <div className="list-container no-spacing row g-0">
            <div className="table-responsive">
              <table className="table table-hover table-fixed" style={{ textAlign: 'left' }}>
                <tbody>
                  {filteredList.map((item) => (
                    <tr
                      key={item.ID}
                      className={item.ID === selectedApikey?.ID ? 'IdSelectedColor' : ''}
                      onClick={() => setSelectedApikey(item)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        <div className="info">
                          <h6 className="truncate-text">{item.UseData}</h6>
                          <div className="truncate-text">{item.Key}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>


                {/* Responsive Tabs Display for Small Screens */}
                <div className="row d-md-none">
            <div className="col-12">
              {selectedApikey && (
                <MDBTabs fill className="mb-0">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('general')}
                      active={activeTab === 'general'}
                      className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >
                      General
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('roles')}
                      active={activeTab === 'roles'}
                      className={`custom-tab-link ${activeTab === 'roles' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >                     
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              )}
            </div>
          </div>
  
          {/* API Key Details and Tabs Content */}
          <div className="col-lg-5 col-md-12 col-sm-12 mt-3">
            {selectedApikey && (
              <div className="tab-content">
                {/* General Tab */}
                {activeTab === 'general' && (
                  <div className="tab-pane active">
                    <MDBInput
                      name="UserID"
                      id="UserID"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedApikey.UserID}
                      onChange={(e) => handleChange('UserID', e.target.value)}
                      label="UserID"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="ApplicationID"
                      id="ApplicationID"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedApikey.ApplicationID}
                      onChange={(e) => handleChange('ApplicationID', e.target.value)}
                      label="ApplicationID"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Key"
                      id="Key"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedApikey.Key}
                      onChange={(e) => handleChange('Key', e.target.value)}
                      label="Key"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="UseData"
                      id="UseData"
                      wrapperClass="mb-2"
                      className="form-control"
                      value={selectedApikey.UseData}
                      onChange={(e) => handleChange('UseData', e.target.value)}
                      label="UseData"
                      type="text"
                      size="md"
                    />
                    <div className="d-flex gap-2">
                      <button onClick={handleSave} className="btn w-100 bg-myColor">
                        Save
                      </button>
                      
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <CreateApikey show= {showModal} handleClose={handleClose}/>
    </div>
  );
}  