import { useCallback, useEffect, useState } from "react";
import {
  fetchData,
  handleListNavigation,
  filterListByName,
  getProperties,
  notify,  
} from "../../Utils/ReusableFunctions";
import "./Country.css";
import { MDBTabs, MDBTabsItem, MDBTabsLink,MDBInput } from "mdb-react-ui-kit";
import { useActiveInput } from "../../Context/ActiveInputTooltip";
import { ToastContainer } from "react-toastify";
import { updateCountry } from "../../DataService/CountryDataService";
import { ApiUrls } from "../../DataService/ApiUrlDataService";
import { useSearch } from "../../Context/SearchContext"; 
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import{faPlus} from '@fortawesome/free-solid-svg-icons';
import {CreateCountry } from "./CreateCountry";
 

export const Country = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { languageOptions, setCustomMessage } = useActiveInput();
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const {searchString} = useSearch();  // Use the search string from context
  const [setSearchString] = useState("");
  const [properties, setProperties] = useState([]);
  const filteredList = filterListByName(countryList, searchString);

  //Show modal to create
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    // Fetch  data again to refresh the list
    fetchData(ApiUrls.countryApi, sessionToken).then((data) => {
      setCountryList(data.value);
    });
  };
  const handleShow = () => setShowModal (true);

  const [activeTab, setActiveTab] = useState('general'); 
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    console.log('Tab clicked:', activeTab); // Debugging
  };

  const handleButtonClick = () => {
   // navigate("/menu/createcountry");
   handleShow();
  };


  const handleGetProperties = useCallback(() => {
    getProperties(ApiUrls.countryPropertiesApi, sessionToken, languageOptions, setProperties);
  }, [sessionToken, languageOptions]);

  useEffect(() => {
    fetchData(ApiUrls.countryApi, sessionToken).then((data) => {
      setCountryList(data.value);
      console.log(data);
      handleGetProperties();
    });
  }, [sessionToken, handleGetProperties]);

     // Set the first country as selected on component mount
     useEffect(() => {
      if (countryList.length > 0) {
        setSelectedCountry(countryList[0]); // Set the first user as selected
      }
    }, [countryList]);



  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(
        event,
        filteredList,
        selectedCountry,
        setSelectedCountry
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedCountry]);

 
  const handleSave = () => {
    updateCountry(
      ApiUrls.countryApi,
      sessionToken,
      selectedCountry,
      fetchData,
      setCountryList,
      notify,
      setCustomMessage
    );
  };

  const handleChange = (field, value) => {
    setSelectedCountry((prevCountry) => ({
      ...prevCountry,
      [field]: value,
    }));
  };

  const handleInputSelect = (isFocused, customMessage) => {
    if (isFocused) {
      setCustomMessage(customMessage);
    } else {
      setCustomMessage('');
    }
  };

  return (
    <div className="container-fluid d-flex flex-column min-vh-100 mt-0 p-0">
      <div className="row">
        {/* Heading and Add Country Button */}
        <div className="row mb-0">
          <div className="col-lg-2 col-12 d-flex mb-0 mt-0 p-0">
            <h5 className="mb-0 px-2">Countries</h5>
            <button
              className="no-border d-flex ms-auto p-0"
              style={{ width: 'auto' }}
              onClick={handleButtonClick}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="myColor"
                size="2x"
                style={{ marginRight: '0.5rem', fontSize: '1.5rem' }}
              />
            </button>
          </div>
  
          {/* column 2: Tabs (if a country is selected) */}
          {selectedCountry && (
          <div className="col-lg-10 col-md-12 col-sm-12 d-none d-md-flex mb-0 align-items-center">
            
            <MDBTabs fill className="mb-0">
              <MDBTabsItem>
                <MDBTabsLink
                  onClick={() => handleTabClick('general')}
                  active={activeTab === 'general'}
                  className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                  style={{ textTransform: 'none', fontSize: '1rem' }}
                >
                  General
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
          </div> 
        )}
          </div>

          <div className="row">
          {/* Country Table */}
          <div className="col-lg-2 col-md-12 col-sm-12 mt-0 p-0">
          <div className="list-container no-spacing row g-0" style={{height:'48rem'}}>
                <div className="table-responsive">
                  <table className="table table-hover table-fixed">
                <tbody>
                  {filteredList.map((item) => (
                    <tr
                      key={item.ID}
                      className={item.ID === selectedCountry?.ID ? "IdSelectedColor" : ""} //keep the background shaded when the user is selected
                      onClick={() => setSelectedCountry(item)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        <div className="info">
                          <h6 className="truncate-text text-start">{item.Name}</h6>                        
                        <div className="truncate-text text-start">Code: {item.Code}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div></div>

           {/* Responsive Tabs Display for Small Screens */}
           <div className="row d-md-none">
            <div className="col-12">
              {selectedCountry && (
                <MDBTabs fill className="mb-0">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('general')}
                      active={activeTab === 'general'}
                      className={`custom-tab-link ${activeTab === 'general' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >
                      General
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick('roles')}
                      active={activeTab === 'roles'}
                      className={`custom-tab-link ${activeTab === 'roles' ? 'active' : ''}`}
                      style={{ textTransform: 'none', fontSize: '1rem' }}
                    >                     
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              )}
            </div>
          </div>

  
          {/* Country Details and Tabs Content */}
          <div className="col-lg-5 col-md-12 col-sm-12 mt-3">
            {selectedCountry && (
              <div className="tab-content">
         {/*General Tab */}   
                {activeTab === 'general' && (
                  <div className="tab-pane active">
                    <MDBInput
                      name="Code"
                      id="Code"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedCountry.Code}
                      onChange={(e) => handleChange("Code", e.target.value)}
                      label="Code"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Name"
                      id="Name"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedCountry.Name}
                      onChange={(e) => handleChange("Name", e.target.value)}
                      label="Name"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Number"
                      id="Number"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedCountry.Number}
                      onChange={(e) => handleChange("Number", e.target.value)}
                      label="Number"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Alpha3"
                      id="Alpha3"
                      wrapperClass="mb-2"
                      className="form-control"
                      value={selectedCountry.Alpha3}
                      onChange={(e) => handleChange("Alpha3", e.target.value)}
                      label="Alpha3"
                      type="text"
                      size="md"
                    />
                    <div>
                      <button
                        onClick={handleSave}
                        className="mb-3 w-100 btn bg-myColor"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          </div>
          </div>
      
      <ToastContainer />
      <CreateCountry show= {showModal} handleClose={handleClose}/>
    </div>
  );
}  