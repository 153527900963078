
export const updateLanguage = async (apiUrl, sessionToken, selectedLanguage, fetchData, setLanguageList, notify, setCustomMessage ) => {
    try {
      if (selectedLanguage) {
        const patchUrl = `${apiUrl}(${selectedLanguage.ID})`;
        const patchData = {
          Code: selectedLanguage.Code,
          Name: selectedLanguage.Name,
          ID: selectedLanguage.ID,
        };
  
        const response = await fetch(patchUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify(patchData),
        });
  
        if (response.ok) {
          console.log("Language updated successfully");
          setCustomMessage("Language updated successfully") 
          const data = await fetchData(apiUrl, sessionToken);
          setLanguageList(data.value);
        } else {
          notify("Error updating language");
          console.error("Error updating language. Status:", response.status);
        }
      }
    } catch (error) {
      notify("Error updating language");
      console.error("Error updating language:", error);
    }
  };
  

export const createLanguage = async (apiUrl, sessionToken, language, notify, setCustomMessage, setLanguage ) => {
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(language),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("Language created:", data);
        setCustomMessage("Language created") 
        setLanguage({
          Code: "",
          Name: "",
        });
        notify("Language added successfully!", 'success'); // Notify success
      } else {
      const errorMessage = await response.text(); // Get error message from response
      notify(`Error creating country: ${errorMessage}`, 'error'); // Notify error
      throw Error("Failed to create country");
      }
    } catch (error) {
      notify("Error creating language");
      console.error("Error creating language:", error);
    }
  };
  