export const updateUser = async (apiUrl, sessionToken, selectedUser, fetchData, setUserList, notify, setActiveInput) => {
    try {
      if (selectedUser) {
        const patchUrl = `${apiUrl}(${selectedUser.ID})`;
        const patchData = {
          Name: selectedUser.Name,
          Username: selectedUser.Username,
          Active: selectedUser.Active,
        };
  
        const response = await fetch(patchUrl, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify(patchData),
        });
  
        if (response.ok) {
          console.log("User updated successfully");
          setActiveInput("userUpdate");
          fetchData(apiUrl, sessionToken).then((data) =>{
            setUserList(data.value);
          })
       
        } else {
          notify("Error updating user");
          console.error("Error updating user. Status:", response.status);
        }
      }
    } catch (error) {
      notify("Error updating user");
      console.error("Error updating user:", error);
    }
  };

  export const createUser = async (apiUrl, sessionToken, user, notify, setUser, setSelectedValue) => {
    console.log(user)
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(user),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("User created:", data);
      //  setActiveInput("userCreated");
        setUser({
          Username: "",
          Active: true,
          TenantID: "",
          Name: "",
        });
        setSelectedValue(null);
      } else {
        notify("Error creating user");
        throw Error("Failed to create user");
      }
    } catch (error) {
      notify("Error creating user");
      console.error("Error creating user:", error);
    }
  };


 // Fetch User ID
 export const fetchUserID = async (sessionToken, username) => { // Accept username as a parameter
  try {
    const response = await fetch('https://auth.decentra.net/odata/user', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${sessionToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const userData = await response.json();
      console.log("User data from /odata/user:", userData); // Debug log

      // Log the username being searched for
      console.log("Looking for username:", username);
      
      // Log all available usernames
      console.log("Available usernames:", userData.value.map(user => user.Username));

      // Look for the signed-in user by matching the username
      const signedInUser = userData.value.find(user => user.Username.trim() === username.trim());

      if (signedInUser) {
        console.log("Signed-In User ID:", signedInUser.ID);
        return signedInUser.ID; // Return the signed-in user's ID
      } else {
        console.error("Signed-in user not found in the user data.");
        return null; // Return null if the user is not found
      }
    } else {
      throw new Error('Failed to fetch user data');
    }
  } catch (error) {
    console.error('Error fetching user ID:', error);
    return null; 
  }
};


/*   //Fetch User IDs
  export const fetchUserID = async (sessionToken) => {
    try {
      const response = await fetch('https://auth.decentra.net/odata/user', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${sessionToken}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const userData = await response.json();     
        console.log("User data from /odata/user:", userData);  
      
        if (userData && Array.isArray(userData.value) && userData.value.length > 0) {
          // Extract and return all user IDs as an array
          const userIds = userData.value.map(user => user.ID);
          console.log("Extracted User IDs:", userIds);
          return userIds; // Return array of user IDs
        } else {
          console.error("User data is missing or the value array is empty:", userData);
          return []; // Return an empty array if no users are found
        }
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user IDs:', error);
      return []; 
    }
  };
   */

  