
export const updateApikey = async (apiUrl, sessionToken, selectedApikey, fetchData, setApikeyList, notify, setCustomMessage) => {
    try {
      const patchUrl = `${apiUrl}(${selectedApikey.ID})`;
      const patchData = {
        UseData: selectedApikey.UseData,
      };
  
      const response = await fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      });
  
      if (response.ok) {
        console.log("Apikey updated successfully");
        setCustomMessage("ApiKey updated successfully");
        const data = await fetchData(apiUrl, sessionToken);
        setApikeyList(data.value);
        notify("API key updated successfully!", 'success'); // Notify success
      } else {
        notify("Error updating apikey");
        console.error("Error updating apikey. Status:", response.status);
      }
    } catch (error) {
      notify("Error updating apikey");
      console.error("Error updating apikey:", error);
    }
  };


  export const createApiKey = async (apiUrl, sessionToken, apikey, notify, setActiveInput, setApikey, setCustomMessage) => {
    try {
      console.log("Creating API key with data:", JSON.stringify(apikey));
  
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(apikey),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("Api key created successfully:", data);
        setCustomMessage("Api key was created successfully");
        setApikey({
          UserID: "",
          ApplicationID: "",
          Key: "",
          UseData: "",
          DeviceID: "",
        });
        notify("API key added successfully!", 'success'); // Notify success
      } else {
        const errorMessage = await response.text(); // Get error message from response
        notify(`Error creating API key: ${errorMessage}`, 'error'); // Notify error
        throw new Error("Failed to create API key");
      }
    } catch (error) {
      console.error("Error creating API key:", error);
      notify("An unexpected error occurred while creating the API key.", 'error'); // Notify unexpected error
    }
  };
  