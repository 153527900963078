import { useParams } from "react-router-dom";
import { fetchData } from "../../Utils/ReusableFunctions";
import { useState, useEffect } from "react";
import { ApiUrls } from "../../DataService/ApiUrlDataService";

const types = ["Email", "Phone"];

export const UserProfile = () => {
  const [user, setUser] = useState({});
  const [mfaUser, setMfaUser] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const sessionToken = localStorage.getItem("bearerToken");
  const { id } = useParams();

  useEffect(() => {
    fetchData(`${ApiUrls.userApi}/${id}`, sessionToken).then((data) => {
      setUser(data);
      console.log(data);
    });
  }, [sessionToken, id]);

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <div className="container text-start">
      <h4>{user.Name}</h4>
      <div className="input-group mt-1 mb-2" style={{ maxWidth: '25rem' }}>
        <span className="input-group-text" id="basic-addon2">
          Email
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Email"
          aria-label="Username"
          aria-describedby="basic-addon2"
          value={user.Username}
          readOnly // Added for better UX
        />
      </div>

      <div className="input-group mt-1 mb-3" style={{ maxWidth: '25rem' }}>  
        <span className="input-group-text form-check form-switch" id="basic-addon3">
        <input
            name="active"
            checked={user.Active}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="active"
           
          />
        </span>
        <label className="form-check-label ms-5" htmlFor="active">
          {user.Active ? "User active" : "User inactive"}
        </label>        
      </div>

      <div>
        <h6>Two Way Authentication Types</h6>
      </div>
      <div>
        {mfaUser.Type}
        <div className="input-group mt-1 mb-2" style={{ maxWidth: '25rem' }}>
          <span className="input-group-text" id="basic-addon1">
            {mfaUser.Data?.substring(0, 3)}
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Username"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={mfaUser.Data?.substring(3)}
            readOnly // Added for better UX
          />
        </div>
        <button
          className="btn btn-sm bg-myColor rounded-0"
          data-bs-toggle="modal"
          data-bs-target="#newAuthType"
        >
          Add new authentication type
        </button>
      </div>

      <div
        className="modal fade"
        id="newAuthType"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                Add new authentication type
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <h6>What type?</h6>
              </div>
              <div className="mb-2">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedType}
                  onChange={handleTypeChange}
                >
                  <option value="" disabled>
                    Select type
                  </option>
                  {types.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              {selectedType === "Email" && (
                <div className="input-group mt-1 mb-2">
                  <span className="input-group-text" id="basic-addon2">
                    @
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Email"
                    aria-describedby="basic-addon2"
                  />
                </div>
              )}
              {selectedType === "Phone" && (
                <div className="input-group mt-1 mb-2">
                  <span className="input-group-text" id="basic-addon2">
                    +45
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Phone number"
                    aria-describedby="basic-addon2"
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary btn-sm">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
