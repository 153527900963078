export const createCountry = async (
  apiUrl,
  sessionToken,
  country,
  notify,
  setCustomMessage,
  setCountry
) => {
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(country),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Country created:", data);
      setCustomMessage("Country created");
      setCountry({
        Alpha3: "",
        Code: "",
        Name: "",
        Number: "",
      });
      notify("Country added successfully!", 'success'); // Notify success
    } else {
      const errorMessage = await response.text(); // Get error message from response
      notify(`Error creating country: ${errorMessage}`, 'error'); // Notify error
      throw Error("Failed to create country");
    }
  } catch (error) {
    notify("Error creating country");
    console.error("Error creating country:", error);
  }
};

export const updateCountry = async (
  apiUrl,
  sessionToken,
  selectedCountry,
  fetchData,
  setCountryList,
  notify,
  setCustomMessage
) => {
  try {
    if (selectedCountry) {
      const patchUrl = `${apiUrl}(${selectedCountry.ID})`;
      const patchData = {
        Code: selectedCountry.Code,
        Name: selectedCountry.Name,
        Number: selectedCountry.Number,
        Alpha3: selectedCountry.Alpha3,
      };

      const response = await fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      });

      if (response.ok) {
        console.log("Country updated successfully");
        setCustomMessage("Country updated successfully");
        const data = await fetchData(apiUrl, sessionToken);
        setCountryList(data.value);
        notify("Country saved successfully!", 'success'); // Notify success
      } else {
        notify("Error updating country");
        console.error("Error updating country. Status:", response.status);
      }
    }
  } catch (error) {
   
    console.error("Error updating country:", error);
  }
};
