import { useActiveInput } from "../../Context/ActiveInputTooltip";

export const InfoBarBottom = () => {
  const { customMessage } = useActiveInput();

  return (
    <div>
      <nav className="navbar fixed-bottom navbar-light bg-secondary d-none d-sm-block">
      <div className="container-fluid">
        <small
          style={{ height: "1rem", fontSize: "0.7rem", marginLeft: "auto" }}
          className="navbar-brand"
        >
      <div>{customMessage}</div>
    </small>
  </div>
</nav>

    </div>
  );
};
