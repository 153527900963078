import React, { createContext, useContext, useState } from 'react';

const ActiveInputContext = createContext();

export const useActiveInput = () => {
  return useContext(ActiveInputContext);
};

export const ActiveInputProvider = ({ children }) => {
  const [customMessage, setCustomMessage] = useState('');
  const [languageOptions, setLanguageOptions] = useState("en")


  return (
    <ActiveInputContext.Provider value={{languageOptions, setLanguageOptions, customMessage, setCustomMessage}}>
      {children}
    </ActiveInputContext.Provider>
  );
};