import React, { useEffect, useState } from "react";
import {
  fetchData,
  filterListByName,
  handleListNavigation,
  notify,
  truncateText,
} from "../../Utils/ReusableFunctions";
import { MDBTabs, MDBTabsItem, MDBTabsLink, MDBInput } from "mdb-react-ui-kit";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ApiUrls } from "../../DataService/ApiUrlDataService";

import { useSearch } from "../../Context/SearchContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUserTag } from "@fortawesome/free-solid-svg-icons";
import { getFilteredRoles } from "../../DataService/RolesDataService";
import RoleTab from "./RoleTab";
import ApplicationTab from "./ApplicationTab";
import "./User.css";
import { CreateUser } from "./CreateUser";

export const Users = () => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { searchString } = useSearch();  // Use the search string from context  
  console.log("session token is: ", sessionToken);

  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  // Array: State to track selected role IDs
  const [selectedRoleIds, setSelectedRoleIds] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);

  const [selectedAppIds, setSelectedAppIds] = useState([]);

  //for opening create user modal
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
    // Fetch  data again to refresh the list
    fetchData(ApiUrls.getUserWithRoleApi, sessionToken).then((data) => {
      setUserList(data.value);
    });
  };
  const handleShow = () => setShowModal(true);

  const filteredList = filterListByName(userList, searchString);
  const [activeTab, setActiveTab] = useState("general");

  useEffect(() => {
    if (sessionToken) {
      getFilteredRoles(
        ApiUrls.filteredRoleApi,
        [1],
        sessionToken,
        notify,
        setFilteredRoles
      );
    }
  }, [sessionToken]);

  useEffect(() => {
    if (sessionToken) {
      fetchData(ApiUrls.getUserWithRoleApi, sessionToken).then((data) => {
        setUserList(data.value);

        // Set the first user as selected
        if (data.value.length > 0) {
          setSelectedUser(data.value[0]);
        }
      });
    }
  }, [sessionToken]);

  /**handleKeyDown */
  useEffect(() => {
    const handleKeyDown = (event) => {
      handleListNavigation(event, filteredList, selectedUser, setSelectedUser);
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [filteredList, selectedUser]);

  //to ensure that selectedUser reflects the latest data from userList.
  useEffect(() => {
    if (userList.length > 0) {
      const updatedUser = userList.find((user) => user.ID === selectedUser?.ID);
      if (updatedUser) {
        setSelectedUser(updatedUser);
        console.log(selectedUser);
      }
    }
  }, [userList, selectedUser?.ID]);

  useEffect(() => {
    const selectedRow = document.querySelector(".IdSelectedColor");
    if (selectedRow) {
      selectedRow.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [selectedUser]);
  

  const handleSave = () => {
    if (selectedUser) {
      const patchUrl = `${ApiUrls.userApi}(${selectedUser.ID})`;
      const patchData = {
        Name: selectedUser.Name,
        Username: selectedUser.Username,
        Active: selectedUser.Active,
      };

      fetch(patchUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(patchData),
      })
        .then((response) => {
          if (response.ok) {
            console.log("User updated successfully");
            fetchData(ApiUrls.getUserWithRoleApi, sessionToken).then((data) => {
              setUserList(data.value);
            });
          } else {
            notify("Error updating user");
            console.error("Error updating user. Status:", response.status);
          }
        })
        .catch((error) => {
          notify("Error updating user");
          console.error("Error updating user:", error);
        });
    }
  };

  const refreshUserList = async () => {
    if (sessionToken) {
      try {
        const data = await fetchData(ApiUrls.getUserWithRoleApi, sessionToken);
        if (data?.value) {
          setUserList(data.value);
          // Check if selectedUser exists before accessing its ID
          if (selectedUser) {
            const updatedUser = data.value.find(
              (user) => user.ID === selectedUser.ID
            );

            if (updatedUser) {
              setSelectedUser(updatedUser);
            }
          }
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error in fetching user roles:", error);
      }
    }
  };

  const handleInputChange = (fieldName, value) => {
    console.log("Selected User:", selectedUser);
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [fieldName]: value,
    }));
  };

  const handleButtonClick = () => {
    // navigate("/menu/createuser");
    handleShow();
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    console.log("Tab clicked:", activeTab); // Debugging
  };

  // Handle checkbox change to select/unselect roles
  const handleCheckboxChange = (roleId) => {
    setSelectedRoleIds((prevSelected) => {
      if (prevSelected.includes(roleId)) {
        // Remove role ID if it's already selected (uncheck)
        return prevSelected.filter((id) => id !== roleId);
      } else {
        // Add role ID if not already selected (check)
        return [...prevSelected, roleId];
      }
    });
  };

  

  return (
    <div className="container-fluid d-flex flex-column min-vh-100 mt-0 p-0 ">
      <div className="row">
        {/* User heading, button, and tabs in the same row */}
        <div className="row mb-0">
          {/* User heading */}
          <div className="col-lg-2 col-12 d-flex mb-0 mt-0 p-0">
            <h5 className="mb-0 px-2">Users</h5>

            <button
              className="no-border d-flex ms-auto p-0"
              style={{ width: "auto" }}
              onClick={handleButtonClick}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="myColor"
                size="2x"
                style={{ marginRight: "0.5rem", fontSize: "1.5rem" }}
              />
            </button>
          </div>

          {/* column 2: Tabs (if a user is selected) */}
          {selectedUser && (
            <div className="col-lg-10 col-md-12 col-sm-12 d-none d-md-flex">
              <MDBTabs fill className="mb-0 mt-0 py-n3">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleTabClick("general")}
                    active={activeTab === "general"}
                    className={`custom-tab-link ${
                      activeTab === "general" ? "active" : ""
                    }`}
                    style={{ textTransform: "none", fontSize: "1rem" }}
                  >
                    General
                  </MDBTabsLink>
                </MDBTabsItem>

                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleTabClick("roles")}
                    active={activeTab === "roles"}
                    className={`custom-tab-link ${
                      activeTab === "roles" ? "active" : ""
                    }`}
                    style={{ textTransform: "none", fontSize: "1rem" }}
                  >
                    Roles
                  </MDBTabsLink>
                </MDBTabsItem>

                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleTabClick("application")}
                    active={activeTab === "application"}
                    className={`custom-tab-link ${
                      activeTab === "application" ? "active" : ""
                    }`}
                    style={{ textTransform: "none", fontSize: "1rem" }}
                  >
                    Application
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
            </div>
          )}
        </div>

        <div className="row">
          {/* User Table */}
          <div className="col-lg-2 mt-0 p-0">
            <div
              className="list-container no-spacing row g-0"
              style={{
                height: window.innerWidth <= 767 ? "48rem" : "auto",
              }}
            >
              <div className="table table-responsive table-fixed">
                <table className="table table-hover">
                  <tbody>
                    {filteredList.map((item) => (
                       <tr
                       key={item.ID}
                       className={item.ID === selectedUser?.ID ? "IdSelectedColor" : ""}
                       onClick={() => setSelectedUser(item)}
                       style={{ cursor: "pointer" }}
                     >
                        <td>
                          <div className="info">
                            <h6 className="truncate-text text-start">
                              {item.Name}
                            </h6>
                            <div className="truncate-text text-start">
                              {truncateText(item.Username, 50)}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/*Spacing after the table*/}
            <div className="row" style={{ height: "1rem" }}></div>
          </div>

          {/* Responsive Tabs Display for Small Screens */}
          <div className="row d-md-none">
            <div className="col-12">
              {selectedUser && (
                <MDBTabs fill className="mb-0 custom-tab-container">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick("general")}
                      active={activeTab === "general"}
                      className={`custom-tab-link ${
                        activeTab === "general" ? "active" : ""
                      }`}
                      style={{ textTransform: "none", fontSize: "0.9rem" }}
                    >
                      General
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick("roles")}
                      active={activeTab === "roles"}
                      className={`custom-tab-link ${
                        activeTab === "roles" ? "active" : ""
                      }`}
                      style={{ textTransform: "none", fontSize: "0.9rem" }}
                    >
                      Roles
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleTabClick("application")}
                      active={activeTab === "application"}
                      className={`custom-tab-link ${
                        activeTab === "application" ? "active" : ""
                      }`}
                      style={{ textTransform: "none", fontSize: "0.9rem" }}
                    >
                      Application
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
              )}
            </div>
          </div>

          {/* User Details and Tabs Content */}

          <div className="col-lg-6 col-md-12 col-sm-12">
            {selectedUser && (
              <div className="tab-content">
                {/*General Tab */}
                {activeTab === "general" && (
                  <div className="tab-pane active mt-4 mb-2">
                    <MDBInput
                      name="Name"
                      id="Name"
                      wrapperClass="mb-3"
                      className="form-control"
                      value={selectedUser.Name}
                      onChange={(e) =>
                        handleInputChange("Name", e.target.value)
                      }
                      label="Name"
                      type="text"
                      size="md"
                    />
                    <MDBInput
                      name="Username"
                      id="email"
                      wrapperClass="mb-2"
                      className="form-control"
                      value={selectedUser.Username}
                      onChange={(e) =>
                        handleInputChange("Username", e.target.value)
                      }
                      label="Email address"
                      type="email"
                      size="md"
                    />
                    <div
                      className="form-check form-switch text-start mb-2 custom-switch"
                      style={{
                        border: "solid",
                        borderWidth: "0.063rem",
                        borderRadius: "0",
                        borderColor: "#BDBDBD",
                      }}
                    >
                      <div className="m-1" style={{ height: "1.25rem" }}>
                        <input
                          name="active"
                          checked={selectedUser.Active}
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="active"
                          onChange={(e) =>
                            handleInputChange("Active", e.target.checked)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        onClick={handleSave}
                        className="mb-3 w-100 btn bg-myColor"
                        style={{ borderRadius: "0 ", borderWidth: "0.063rem" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}

                {/*Roles Tab*/}
                {activeTab === "roles" && (
                  <div className="tab-pane active mt-4 mb-2">
                    {activeTab === "roles" && (
                      <RoleTab
                        selectedUser={selectedUser}
                        selectedRoleIds={selectedRoleIds}
                        refreshUserList={refreshUserList}
                      />
                    )}

                    {/* Table to show assigned roles with description */}

                    <div className="col-lg-30 mt-0 p-0">
                      <div className="list-container no-spacing row g-0">
                        <div className="table table-responsive table-container">
                          <table className="role-table">
                            <thead>
                              <tr>
                                <th className="text-start"></th>
                                <th
                                  className="text-start"
                                  style={{ width: "30%", fontWeight: "bold" }}
                                >
                                  Role
                                </th>
                                <th
                                  className="text-start"
                                  style={{ width: "70%", fontWeight: "bold" }}
                                >
                                  Description
                                </th>
                              </tr>
                            </thead>

                            <tbody className="table-wrapper">
                              {selectedUser?.RoleAssignments?.map((role) => (
                                <tr key={role.ID}>
                                  <td className="text-start">
                                    <input
                                      type="checkbox"
                                      style={{ marginRight: "0.5rem" }}
                                      onChange={() =>
                                        handleCheckboxChange(role.ID)
                                      } // Call handler on change
                                      checked={selectedRoleIds.includes(
                                        role.ID
                                      )} // Keep checkbox checked state synced
                                    />
                                  </td>
                                  <td className="d-flex align-items-center">
                                    <FontAwesomeIcon
                                      icon={faUserTag} // icon
                                      className="myColor" // Custom color
                                      style={{ marginRight: "0.5rem" }}
                                    />
                                    <span>
                                      {role.Role.Name|| "No Code Available"}
                                    </span>{" "}
                                    {/* Role code */}
                                  </td>
                                  <td className="text-start truncate">
                                    {role.Role.Description
                                      ? truncateText(role.Role.Description, 50)
                                      : "No Description Available"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>{" "}
                        </div>
                      </div>
                    </div>

                    {/*Spacing after the table*/}
                    <div style={{ height: "1rem" }}></div>
                  </div>
                )}
              </div>
            )}

            {/*Applications Tab */}
            <div className="tab-pane active mt-4 mb-2">
              {activeTab === "application" && (
                <ApplicationTab
                  selectedUser={selectedUser}
                  selectedAppId={selectedAppIds}
                  refreshUserList={refreshUserList}
                  sessionToken={sessionToken}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <CreateUser show={showModal} handleClose={handleClose} />
    </div>
  );
};
